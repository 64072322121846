import * as React from 'react';

function IconDoubleQuoteClose(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 19" fill="none" {...props}>
      <path
        d="M15.318 18.714C20.753 16.197 24 11.514 24 6.34 24 2.704 21.741.048 18.635.048c-2.682 0-4.8 1.957-4.8 4.544 0 1.958 1.27 3.565 3.177 4.055 2.188.49 2.258.56 2.258 1.678 0 2.447-1.835 4.754-5.364 6.711l1.412 1.678zm-13.836 0c5.436-2.517 8.683-7.2 8.683-12.374 0-3.636-2.26-6.292-5.365-6.292C2.118.048 0 2.005 0 4.592 0 6.55 1.27 8.157 3.176 8.647c2.189.49 2.26.56 2.26 1.678 0 2.447-1.836 4.754-5.365 6.711l1.411 1.678z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoIconDoubleQuoteClose = React.memo(IconDoubleQuoteClose);
export default MemoIconDoubleQuoteClose;
