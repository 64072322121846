/* eslint-disable react/prop-types */
import React from 'react';

import FixedTopBarScholarship from './FixedTopBarScholarshipUI';
import { usePromotionData } from './usePromotionData';

export const FixedTopBarScholarshipScheduleNOffline = props => {
  const promotionScholarships = usePromotionData({ filterBy: 'offline' });
  if (!promotionScholarships.length) return null;
  return <FixedTopBarScholarship data={promotionScholarships} {...props} />;
};

export default FixedTopBarScholarshipScheduleNOffline;
