import * as React from 'react';
import styled from 'styled-components';
import { fromScreen } from 'utils/media-query/responsive.util';
import { LazyImage } from 'components/LazyImage';
import { colorsV2 } from 'style/colors-v2';
import { Link } from 'gatsby';

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  place-items: center;
  a {
    display: flex;
    justify-content: center;
    border-radius: 8px;
    padding: 24px 0;
    width: 100%;
    border: 1px solid ${colorsV2.gray20};
    &:hover {
      border: 1px solid ${colorsV2.primary100};
    }
  }
  img {
    object-fit: cover;
    height: 24px;
  }

  ${fromScreen(458)} {
    gap: 24px;
  }

  ${fromScreen(776)} {
    grid-template-columns: repeat(3, 1fr);
    a {
      padding: 32px 0;
    }
    img {
      height: 36px;
    }
  }
  ${fromScreen(1144)} {
    a {
      padding: 48px 0;
    }
    img {
      height: 44px;
    }
  }
`;

export const NewsSiteFeatureList = props => {
  const { featureData } = props;

  return (
    <Container>
      {featureData?.map(item => {
        const { id, logo, link, name } = item;
        return (
          <Link
            rel="nofollow noreferrer noopener"
            target="_blank"
            href={link}
            key={id}
          >
            <LazyImage src={logo.url} alt={name} />
          </Link>
        );
      })}
    </Container>
  );
};
