import * as React from 'react';

function IconDoubleQuoteOpen(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 19" fill="none" {...props}>
      <path
        d="M8.682.048C3.247 2.564 0 7.248 0 12.422c0 3.636 2.259 6.292 5.365 6.292 2.682 0 4.8-1.957 4.8-4.544 0-1.958-1.27-3.566-3.177-4.055-2.188-.49-2.259-.56-2.259-1.678 0-2.447 1.836-4.754 5.365-6.711L8.682.048zm13.836 0c-5.436 2.516-8.683 7.2-8.683 12.374 0 3.636 2.26 6.292 5.365 6.292 2.682 0 4.8-1.957 4.8-4.544 0-1.958-1.27-3.566-3.176-4.055-2.189-.49-2.26-.56-2.26-1.678 0-2.447 1.836-4.754 5.365-6.711L22.518.048z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoIconDoubleQuoteOpen = React.memo(IconDoubleQuoteOpen);
export default MemoIconDoubleQuoteOpen;
