import * as React from 'react';
import styled from 'styled-components';
import { fromScreen } from '../../utils/media-query/responsive.util';
import { CardInfo } from './ArticleFeatureList';

const Action = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  justify-content: center;

  ${fromScreen(776)} {
    grid-template-columns: 344px;
  }
`;

const ListLayout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 24px;
  .card {
    .info {
      margin-top: 12px;
    }
    .desc {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  ${fromScreen(375)} {
    grid-template-columns: repeat(auto-fill, minmax(344px, 1fr));
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 40px;
`;

export const ArticleLatestList = props => {
  const { data, loadMoreButton } = props;

  return (
    <Container>
      <ListLayout>
        {data?.map(item => {
          const cardProps = {
            logoUrl: item.newsSite?.logo?.url,
            title: item.title,
            description: item.description,
            postedAt: item.postedAt,
            readMoreButtonLabel: item.readMoreButtonLabel,
            thumbnailUrl: item.thumbnail?.url,
            link: item.link,
            nameSite: item.newsSite?.name
          };
          return (
            <div key={item.id} className="card">
              <CardInfo {...cardProps} />
            </div>
          );
        })}
      </ListLayout>
      <Action>{loadMoreButton}</Action>
    </Container>
  );
};
