import * as React from 'react';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import MainLayout from '../components/Layout/MainLayout';
import HeaderSchedulePage from '../shared/Header/HeaderSchedulePage';
import PageBanner from 'shared/Center/CenterHeader';
import { ArticleFeatureList } from 'shared/ArticleAboutDol/ArticleFeatureList';
import { ArticleSection } from 'shared/ArticleAboutDol/ArticleSection';
import { NewsSiteFeatureList } from 'shared/ArticleAboutDol/NewsSiteFeatureList';
import { NewSiteQuotes } from 'shared/ArticleAboutDol/NewSiteQuotes';
import { ArticleLatestList } from 'shared/ArticleAboutDol/ArticleLatestList';
import MySEO from 'shared/MySEO';
import styled from 'styled-components';
import { fromScreen } from 'utils/media-query/responsive.util';
import { apiCacheKeys } from '../apis/api.urls';
import {
  getNewSitesQuote,
  getNewSitesFeture,
  getListedArticle
} from '../service/article-service';
import { useInfiniteQuery, useQuery } from 'react-query';
import { SORT_BY_ORDERING_ASC } from '../constant';
import { FooterEndSection } from 'components/FooterEndSection/FooterEndSection';
import { ButtonLoadMore } from 'components/ButtonLoadMore/ButtonLoadMore';
import { useDeviceDetect3 } from 'utils/hooks/useDeviceQuery.hook';

const Space = styled.div`
  > section {
    margin-bottom: 32px;
    &:first-child {
      margin: 32px 0 68px 0;
    }
    ${fromScreen(776)} {
      margin-bottom: 100px;
      &:first-child {
        margin: 100px 0 160px 0;
      }
    }
  }
`;

const featureParams = {
  size: 4,
  sort: SORT_BY_ORDERING_ASC
};

const Page = ({ location }) => {
  const { isMobile } = useDeviceDetect3();

  const listingParams = {
    size: isMobile ? 3 : 6,
    sort: SORT_BY_ORDERING_ASC
  };

  const { data: quoteData = {} } = useQuery(
    [apiCacheKeys.publicService.GET_NEW_SITES_QUOTE],
    () => getNewSitesQuote()
  );

  const { data: featureData = {} } = useQuery(
    [apiCacheKeys.publicService.GET_NEW_SITES_FEATURE],
    () => getNewSitesFeture()
  );

  const { data: featureDataListed } = useQuery(
    [apiCacheKeys.publicService.GET_NEW_SITES_LISTED, featureParams],
    () => getListedArticle(featureParams)
  );

  const excludedIds = featureDataListed?.content?.map(({ id }) => id) || [];

  const {
    data: listingData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(
    [apiCacheKeys.publicService.GET_LISTED_IELTS_BLOGS_LANDING, listingParams],
    ({ pageParam = 0 }) =>
      getListedArticle({
        ...listingParams,
        page: pageParam,
        excludedId: excludedIds
      }),
    {
      enabled: Boolean(excludedIds?.length),
      getNextPageParam: lastPage => {
        const { number, last = false } = lastPage;
        if (last) {
          return undefined;
        }
        if (isNaN(number)) {
          return 0;
        }
        return number + 1;
      }
    }
  );

  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="Báo chí nói gì về DOL"
        h1="Báo chí viết về DOL"
        slug={location.pathname}
      />
      <FixedTopBarScholarshipScheduleNOffline />
      <PageBanner
        title={`<span class="highlight">Báo chí</span> viết về DOL`}
        description="Là học viện Tiếng Anh Tư Duy đầu tiên tại Việt Nam - DOL vinh dự được nhiều tòa báo, đài truyền hình và các phương tiện truyền thông đại chúng vinh danh, đưa tin và làm các phóng sự về hiệu quả của Linearthinking cũng như những thành quả của DOL đã đạt được trong nhiều năm qua..."
      />
      <Space>
        <ArticleSection
          title="Các bài báo tiêu biểu"
          content={<ArticleFeatureList data={featureDataListed?.content} />}
        />
        <ArticleSection
          title="Báo chí viết về DOL"
          content={<NewsSiteFeatureList featureData={featureData?.content} />}
        />
        <NewSiteQuotes data={quoteData} />
        <ArticleSection
          title="Các bài báo mới nhất"
          content={
            <ArticleLatestList
              data={listingData?.pages.flatMap(p => p.content)}
              loadMoreButton={
                hasNextPage ? (
                  <ButtonLoadMore
                    text="Xem thêm bài báo"
                    loading={isFetchingNextPage}
                    onClick={fetchNextPage}
                  />
                ) : (
                  <FooterEndSection message="Bạn đã xem hết bài báo" />
                )
              }
            />
          }
        />
      </Space>
    </MainLayout>
  );
};

export default Page;
