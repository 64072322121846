import * as React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';

import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';

import { IconCourseArrowRight } from 'components/Icon/SVGIcons';

import cl from 'classnames';

const Main = styled.div`
  position: relative;
  display: grid;
  gap: 4px;
  align-items: center;
  grid-template-columns: 1fr 16px;
  width: fit-content;

  cursor: pointer;

  .icon-wrapper {
    display: flex;
    align-items: center;
  }

  &:after {
    position: absolute;
    bottom: 0px;
    content: '';
    width: 100%;
    transform: scaleX(0);
    transform-origin: 0% 50%;
    transition: transform 250ms ease-in-out;
  }

  &:hover:after {
    transform-origin: 0% 50%;
    transform: scaleX(1);
  }

  &.primary {
    > p,
    div {
      color: ${colorsV2.primary100};
    }
    &:after {
      border-bottom: solid 1px ${colorsV2.primary100};
    }
  }
`;

const ButtonText = ({ content, type, ...rest }) => {
  return (
    <Main className={cl('button-text', type)} {...rest}>
      <Typography variant="semi-bold/14-20">{content}</Typography>
      <div className="icon-wrapper">
        <IconCourseArrowRight />
      </div>
    </Main>
  );
};

ButtonText.propTypes = {
  type: PropTypes.string,
  content: PropTypes.string
};

ButtonText.defaultProps = {};

export default React.memo(ButtonText);
