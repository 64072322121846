import { apis } from '../apis/api.config';
import { apiUrls } from '../apis/api.urls';

export const getNewSitesQuote = async () => {
  const { data } = await apis.publicService.get(
    apiUrls.publicService.GET_NEW_SITES_QUOTE
  );

  return data;
};

export const getNewSitesFeture = async () => {
  const { data } = await apis.publicService.get(
    apiUrls.publicService.GET_NEW_SITES_FEATURE
  );

  return data;
};

export const getListedArticle = async params => {
  const { data } = await apis.publicService.get(
    apiUrls.publicService.GET_NEW_SITES_LISTED,
    {
      params
    }
  );

  return data;
};
