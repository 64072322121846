import * as React from 'react';

import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';
import { Typography } from 'components/DesignSystemV2/Typography';
import MemoIconDoubleQuoteClose from 'shared/Linearthinking/icons/IconDoubleQuoteClose';
import MemoIconDoubleQuoteOpen from 'shared/Linearthinking/icons/IconDoubleQuoteOpen';
import { LazyImage } from 'components/LazyImage';
import { withHomePageSection } from 'components/withHomePageSection';

const Main = styled.section`
  display: grid;
  place-items: center;
  text-align: center;
  gap: 32px;
  padding: 64px 0;

  .content {
    display: grid;
    gap: 20px;
    img {
      height: 16px;
    }
  }

  .double-quote-icon {
    color: ${colorsV2.primary100};
    font-size: 13px;
    position: relative;
    top: -4px;
  }

  ${fromScreen(776)} {
    .content {
      > p {
        font-size: 32px;
        line-height: 40px;
      }
      gap: 32px;
      width: 650px;
      img {
        height: 24px;
      }
    }
    .double-quote-icon {
      font-size: 24px;
    }
  }
`;

export const NewSiteQuotes = withHomePageSection(
  ({ data }) => {
    return (
      <Main className="linearthinking-testimonial">
        <div className="content">
          <Typography variant="semi-bold/20">
            <span className="double-quote-icon">
              <MemoIconDoubleQuoteOpen />
            </span>
            {` ${data.quotedContent} `}
            <span className="double-quote-icon">
              <MemoIconDoubleQuoteClose />
            </span>
          </Typography>

          <div className="author">
            <LazyImage src={data.logo?.url} alt={data.name} />
          </div>
        </div>
      </Main>
    );
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  }
);
