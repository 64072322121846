import * as React from 'react';
import styled from 'styled-components';
import { fromScreen, toScreen } from 'utils/media-query/responsive.util';
import { Typography } from 'components/DesignSystemV2/Typography';
import { AspectRatio } from 'components/AspectRatio/AspectRatio';
import { LazyImage } from 'components/LazyImage';
import ButtonText from 'components/ButtonText';
import moment from 'moment';
import { MONTH_NAME_FORMAT } from '../../constant';
import { colorsV2 } from 'style/colors-v2';
import { Link } from 'gatsby';

const ThumbnailImg = styled(LazyImage)`
  img {
    object-fit: cover;
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    position: absolute;
    border-radius: 8px;
  }
`;
const Info = styled.div`
  display: grid;
  gap: 4px;
  .title {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: ${colorsV2.gray40};
`;

const CreateBy = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  gap: 8px;
  img {
    height: 16px;
  }
`;

const Container = styled.section`
  display: grid;
  gap: 32px;

  .highlight-article {
    ${Info} {
      margin-top: 12px;
    }
    .desc {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      ${fromScreen(1144)} {
        -webkit-line-clamp: 5;
      }
    }
  }

  .list-article {
    display: grid;
    gap: 32px;
    .card {
      display: grid;
      grid-template-columns: 144px 1fr;
      gap: 12px;
      > div {
        height: max-content;
        .desc {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      ${toScreen(1143)} {
        .title {
          font-size: 16px;
          line-height: 24px;
        }
        .desc {
          display: none !important;
        }
      }
    }
  }
  ${fromScreen(776)} {
    grid-template-columns: 1fr 1fr;
  }

  ${fromScreen(1144)} {
    .list-article {
      .card {
        grid-template-columns: 240px 1fr;
      }
    }
  }
`;

const Description = styled(Typography).attrs({
  variant: 'regular/14-20',
  color: colorsV2.black80
})``;

export const CardInfo = ({
  logoUrl,
  title,
  description,
  postedAt,
  readMoreButtonLabel,
  thumbnailUrl,
  link,
  nameSite
}) => {
  console.log(nameSite);
  return (
    <>
      <Link rel="nofollow noreferrer noopener" target="_blank" href={link}>
        <AspectRatio>
          <ThumbnailImg src={thumbnailUrl} alt={title} />
        </AspectRatio>
      </Link>
      <Info className="info">
        <CreateBy>
          <LazyImage src={logoUrl} alt={nameSite} />
          <Dot />
          <Typography variant="regular/14-20" color={colorsV2.gray100}>
            {moment(postedAt).format(MONTH_NAME_FORMAT)}
          </Typography>
        </CreateBy>
        <Link rel="nofollow noreferrer noopener" target="_blank" href={link}>
          <Typography className="title" variant="semi-bold/20-28" as="h3">
            {title}
          </Typography>
        </Link>
        <Description className="desc">{description}</Description>
        <Link rel="nofollow noreferrer noopener" target="_blank" href={link}>
          <ButtonText content={readMoreButtonLabel} type="primary" />
        </Link>
      </Info>
    </>
  );
};

export const ArticleFeatureList = props => {
  const { data } = props;
  const highlightFeature = data?.[0] || {};
  const features = data?.slice(1);

  const cardProps = {
    logoUrl: highlightFeature.newsSite?.logo?.url,
    nameSite: highlightFeature.newsSite?.name,
    title: highlightFeature.title,
    description: highlightFeature.description,
    postedAt: highlightFeature.postedAt,
    readMoreButtonLabel: highlightFeature.readMoreButtonLabel,
    thumbnailUrl: highlightFeature.thumbnail?.url,
    link: highlightFeature.link
  };

  return (
    <Container>
      <div className="highlight-article">
        <CardInfo {...cardProps} />
      </div>
      <div className="list-article">
        {features?.map(item => {
          const {
            id,
            newsSite,
            title,
            description,
            postedAt,
            readMoreButtonLabel,
            link,
            thumbnail
          } = item;
          return (
            <div key={id} className="card">
              <CardInfo
                logoUrl={newsSite?.logo?.url}
                title={title}
                description={description}
                postedAt={postedAt}
                readMoreButtonLabel={readMoreButtonLabel}
                link={link}
                thumbnailUrl={thumbnail?.url}
                nameSite={newsSite?.name}
                isCardList
              />
            </div>
          );
        })}
      </div>
    </Container>
  );
};
